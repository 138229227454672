<template>
  <transition>
    <DxDataGrid
      class="grid-box"
      key-expr="id"
      ref="vacationUseGrid"
      :data-source="dataGrid.dataSource"
      :show-borders="false"
      :show-column-headers="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="false"
      :hover-state-enabled="true"
      :word-wrap-enabled="true"
      :no-data-text="this.$_msgContents('CMN_NO_DATA')"
    >
      <DxLoadPanel :enabled="true" />
      <DxScrolling mode="standard" />
      <DxHeaderFilter :visible="true" />
      <DxFilterRow :visible="true" />

      <DxColumn
        caption="휴가구분"
        data-field="vacationTypePtCd"
        :allow-editing="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
        :calculate-sort-value="data => calculateSortValue(data, 'vacationTypePtCd')"
      >
        <DxLookup :data-source="codes.vacationTypePtCd.dataSource" value-expr="codeId" display-expr="codeNm" />
      </DxColumn>
      <DxColumn
        caption="휴가구분상세"
        data-field="vacationTypeCd"
        :allow-editing="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
        :calculate-sort-value="data => calculateSortValue(data, 'vacationTypeCd')"
      >
        <DxLookup :data-source="codes.vacationTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
      </DxColumn>
      <DxColumn
        caption="휴가일자"
        data-field="vacationYmd"
        :allow-editing="false"
        :allow-header-filtering="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
        width="180"
        :calculate-display-value="
          rowData =>
            `${formatDate(rowData.vacationStartYmd, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD')}
                     ~ 
                    ${formatDate(rowData.vacationEndYmd, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD')}`
        "
        :sort-order="'asc'"
      />
      <DxColumn
        caption="휴가시작시간"
        data-field="vacationStartTime"
        :allow-editing="false"
        :allow-header-filtering="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
        :calculate-display-value="
          rowData => {
            return isEmpty(rowData.vacationStartTime) ? '' : formatDate(rowData.vacationStartTime, 'HHmm', 'HH:mm');
          }
        "
      />
      <DxColumn
        caption="휴가종료시간"
        data-field="vacationEndTime"
        :allow-editing="false"
        :allow-header-filtering="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
        :calculate-display-value="
          rowData => {
            return isEmpty(rowData.vacationEndTime) ? '' : formatDate(rowData.vacationEndTime, 'HHmm', 'HH:mm');
          }
        "
      />
      <DxColumn
        caption="휴가차감일수"
        data-field="vacationDayoff"
        :allow-editing="false"
        :allow-sorting="true"
        alignment="center"
        :visible="true"
      />
    </DxDataGrid>
  </transition>
</template>

<script>
  import { DxDataGrid, DxColumn, DxLookup, DxScrolling, DxLoadPanel, DxFilterRow, DxHeaderFilter } from 'devextreme-vue/data-grid';
  import { formatDate, isEmpty, isSuccess } from '@/plugins/common-lib';

  let vm = this;

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxLoadPanel,
      DxLookup,
      DxScrolling,
      DxFilterRow,
      DxHeaderFilter,
    },
    props: {
      contentData: Object,
    },
    data() {
      return {
        codes: {
          vacationTypePtCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        dataGrid: {
          dataSource: [],
        },
      };
    },
    computed: {},
    methods: {
      isEmpty,
      formatDate,
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'vacationTypePtCd') {
          return this.codes.vacationTypePtCd.dataSource.find(e => data.vacationTypePtCd === e.codeId).codeNm;
        } else if (clsCd === 'vacationTypeCd') {
          return this.codes.vacationTypeCd.dataSource.find(e => data.vacationTypeCd === e.codeId).codeNm;
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        const payload = {
          actionname: 'EWM_HR_VACATION_USE_LIST',
          data: {
            agtId: this.contentData.agtId,
            vacationYmd: this.contentData.vacationYear,
          },
          loading: false,
          useErrorPopup: true,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
          this.dataGrid.dataSource.forEach(d => {
            let filterParent = this.codes.vacationTypeCd.dataSource.find(v => v.codeId === d.vacationTypeCd);
            d.vacationTypePtCd = filterParent.parentId;
          });
        }
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_vacation_division');
      },
      /** @description : 라이프사이클 created 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.vacationTypePtCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 2);
          this.codes.vacationTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 3);
        });

        this.selectDataList();
      },
      /** @description : 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {},
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
    destroyed() {},
  };
</script>
